import React, { useContext } from 'react'
import parse from 'html-react-parser'
import Form from '@cloudscape-design/components/form'
import { AppContext } from './../App'

// https://cloudscape.design/components/form
export function RadForm ({ ...rest }) {
  const { error } = useContext(AppContext)
  const errorText = error?.message ?? error?.code

  return (
    <Form
      errorText={errorText != null ? parse(errorText) : null}
      {...rest}
    />
  )
}
