import React, { useContext } from 'react'
import { AppContext } from './../App'
import Button from '@cloudscape-design/components/button'

// https://cloudscape.design/components/button
export function RadButton ({ disabled, formAction, ...rest }) {
  const { loadingCount } = useContext(AppContext)

  return (
    <Button
      disabled={loadingCount > 0 ? true : disabled}
      formAction={formAction ?? 'none'}
      {...rest}
    />
  )
}
